const DEFAULT_FIELDS = [
    // /*  0 */ {value:'defName', label:'Name',type:'text'},
    /*  1 */ {value:'defCreated_By', label:'Created By', type:'user'}, // change fieldtype for testing purpose
    /*  2 */ {value:'defModified_By', label:'Modified By', type:'user'},
    /*  3 */ {value:'defCreated_At', label:'Created At', type:'datetime'},
    /*  4 */ {value:'defModified_At', label:'Modified At', type:'datetime'},
    /*  5 */ {value:'defFlow_Name', label:'Flow Name', type:'text'},
    /*  6 */ {value:'defCompleted_At', label:'Completed At', type:'datetime'},
    // /*  7 */ {value:'defStatus', label:'Status', type:'text'},
    /*  8 */ {value:'defSubmitted_At', label:'Submitted At', type:'datetime'},
    // /*  9 */ {value:'defRequest_Number', label:'Request Number', type:'number'},
    // /* 10 */ {value:'defIs_Public_Form', label:'Is Public Form', type:'switch'},
    // /* 11 */ {value:'defCounter', label:'Counter', type:'number'},
    /* 12 */ {value:'defExpire_At', label:'Expire At', type:'datetime'},
    // /* -- */ {value:'defCurrent_User', label:'Current User', fieldType:'user'} <--- added from Visibility/index.js
];

const CONDITIONS = {
    defFields: DEFAULT_FIELDS
};

export const MAX_FILE_SIZE = 20;
export const SPECIAL_CHAR_REGEX = /(----------------------------------------------------------------...)/;

export default CONDITIONS;